import React from "react"
import { Button } from "../components"
import { SELF_URL } from "../../constants/urls"
import styles from "./card.module.scss"

const Card = ({ title, slug, cover, description, buttonLabel }) => {
  return (
    <a
      className={styles.mainCardWrapper}
      href={`${SELF_URL}/${slug}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={cover} />
      <h2 title={title}>{title}</h2>
      <div className={styles.buttonWrapper}>
        <Button label={buttonLabel} type="icon" />
      </div>
    </a>
  )
}

export default Card
