/* eslint-disable react/button-has-type */
import React from "react"
import styles from "./button.module.scss"

import arrow from "../../../static/icons/arrow-white.svg"

const Button = ({ label, emoji, onClick, type, disabled }) => {
  return (
    <button
      className={[styles.base, styles[type], disabled && styles.disabled].join(
        " "
      )}
      onClick={onClick}
    >
      {label}
      {type === "template" && <span>{emoji}</span>}
    </button>
  )
}

export default Button
