import React from "react"
import { Helmet } from "react-helmet"

const AUTHOR = "Campax"
const LANDING_PAGE = "https://collect.campax.org"

const HeadContainer = ({
  language = "de",
  url = "",
  title,
  slug,
  shortDescription,
  longDescription,
  keywords,
  twitterAuthor,
  previewPicture,
  favicon,
}) => (
  <Helmet htmlAttributes={{ lang: language }}>
    <meta httpEquiv="content-language" content={language} />

    <meta charset="utf-8" />
    <title>{title}</title>
    <meta name="title" content={shortDescription} />
    <meta image={favicon} />
    <meta name="description" content={longDescription} />
    <meta name="keywords" content={keywords} />

    <meta name="robots" content="index, follow" />
    <meta property="author" content={AUTHOR} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={LANDING_PAGE + url} />
    <meta property="og:site_name" content={title} />
    <meta property="og:title" content={shortDescription} />
    <meta property="og:description" content={longDescription} />
    <meta property="og:image" content={previewPicture} />
    <meta property="og:image:secure_url" content={previewPicture} />
    <meta property="og:image:type" content="image/jpeg" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />

    <meta itemProp="name" content={shortDescription} />
    <meta itemProp="description" content={longDescription} />
    <meta itemProp="image" content={previewPicture} />

    <meta name="twitter:creator" content={twitterAuthor} />
    <meta name="twitter:site" content={twitterAuthor} />
    <meta name="twitter:title" content={shortDescription} />
    <meta name="twitter:description" content={longDescription} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:image" content={previewPicture} />
    <meta name="viewport" content="width=device-width, initial-scale=1" />

    <link rel="icon" type="image/png" href={favicon} sizes="32x32" />
    <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
    <link
      rel="preload"
      href={`https://widget.proca.app/d/campax/collect/${language}?v=pandemicv2`}
      as="script"
    />
    <script src="https://www.videoask.com/embed/embed.js" />
    <script src='//fw-cdn.com/2323011/2952569.js' chat='true'> </script>
  </Helmet>
)

export default HeadContainer
