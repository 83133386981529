import React, { useState } from "react"
import Autosuggest from "react-autosuggest"
import styles from "./autoSuggest.module.scss"

import { SELF_URL } from "../../constants/urls"

import SearchIcon from "../../../static/icons/searchIcon.png"

const getSuggestionValue = suggestion => suggestion.title

// Use your imagination to render suggestions.
const renderSuggestion = ({ slug, title }) => (
  <a
    className={styles.suggestionLink}
    href={`${SELF_URL}/${slug}`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <div>
      <p>{title}</p>
    </div>
  </a>
)

const renderInputComponent = props => (
  <span className={styles.adoWrapper}>
    <input {...props} />
    <img
      className={styles.adoImage}
      src={SearchIcon}
      alt="search bar magnifier"
    />
  </span>
)

const AutoSuggestComponent = ({ data, placeholder }) => {
  const [value, setValue] = useState("")
  const [suggestions, setSuggestions] = useState([])

  const onSuggestionsFetchRequested = ({ value }) =>
    setSuggestions(getSuggestions(value))
  const onSuggestionsClearRequested = () => setSuggestions([])

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : data.filter(el => el.title.toLowerCase().includes(inputValue))
  }

  const inputProps = {
    placeholder,
    value,
    onChange: event => setValue(event.target.value),
  }

  return (
    <Autosuggest
      theme={styles}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={(_, val) => setValue(val.suggestionValue)}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      renderInputComponent={renderInputComponent}
      inputProps={inputProps}
    />
  )
}

export default AutoSuggestComponent
