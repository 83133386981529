/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react"
import { navigate } from "gatsby"
import dropdown from "../../../static/icons/dropArrow.svg"
import { SELF_URL } from "../../constants/urls"

import styles from "./languageBarComponent.module.scss"
import logo from "../../../static/logos/collectCampax.png"

const LANGUAGE_MAP = {
  it: "Italiano",
  de: "Deutsch",
  fr: "Francais",
}

const LanguageBarComponent = ({ currentLanguage = "de", sets }) => {
  const [toggle, toggleLanguage] = useState(false)
  const differentLanguageEntries = sets.filter(
    set => set.acf.language !== currentLanguage
  )
  const hasOtherLanguages = sets.length > 1

  return (
    <div
      onClick={() => (hasOtherLanguages ? toggleLanguage(!toggle) : null)}
      className={[styles.root, !hasOtherLanguages && styles.emptyRootBar].join(
        " "
      )}
    >
      <div className={styles.logoContainer}>
        <img src={logo} alt="campax" onClick={() => navigate(SELF_URL)} />
      </div>
      {hasOtherLanguages && (
        <div className={styles.languageContainer}>
          <h3>{LANGUAGE_MAP[currentLanguage]}</h3>
          {hasOtherLanguages && <img src={dropdown} alt="arrows" />}
          {toggle && (
            <div className={styles.dropdownContainer}>
              {differentLanguageEntries.map(entry => (
                <h3 onClick={() => navigate(`${SELF_URL}/${entry.slug}`)}>
                  {LANGUAGE_MAP[entry.acf.language]}
                </h3>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default LanguageBarComponent
