export default [
  { id: 0, icon: "twitter", url: "https://twitter.com/campaxorg" },
  {
    id: 1,
    icon: "facebook",
    url: "https://www.facebook.com/campaxorg",
  },
  {
    id: 2,
    icon: "instagram",
    url: "https://www.instagram.com/campaxorg/",
  },
  {
    id: 3,
    icon: "linkedin",
    url: "https://www.linkedin.com/company/campax/",
  },
]
