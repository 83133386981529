import React from "react"

import formSteps from "../../constants/formSteps"

import styles from "./formStepper.module.scss"

const FormStepper = ({ language }) => {
  return (
    <div className={styles.formStepper}>
      {formSteps.map(step => (
        <div className={styles.step} key={step.id}>
          <h1>{step.stepNumber}</h1>
          <span>{language[step.stepText]}</span>
        </div>
      ))}
    </div>
  )
}

export default FormStepper
