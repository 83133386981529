import React from "react"
import { navigate } from "gatsby"
import footerSocialData from "../../constants/campaxSocial"

import twitter from "../../../static/icons/twitter.svg"
import facebook from "../../../static/icons/facebook.svg"
import instagram from "../../../static/icons/instagram.svg"
import linkedin from "../../../static/icons/linkedin.svg"

import styles from "./footer.module.scss"

const icons = {
  twitter,
  facebook,
  instagram,
  linkedin,
}

const Footer = ({
  footerVersion,
  footerSocial,
  copyrights,
  de,
  fr,
  it,
  privacy,
  impressum,
  privacyLink,
  impressumLink,
}) => {
  return (
    <footer>
      <div>
        <h6>{footerSocial}</h6>
        <div>
          {footerSocialData.map(social => (
            <a key={Math.random()} href={social.url} target="_blank">
              <img
                className={styles.footerIcon}
                src={icons[social.icon]}
                alt={social.url}
              />
            </a>
          ))}
        </div>
      </div>
      <div className={styles.footerLinks}>
        <a href={privacyLink}>{privacy}</a>
        <a href={impressumLink}>{impressum}</a>
      </div>
      <div className={styles.languagesWrapper}>
        <h6 className={styles.pickTitle}>{footerVersion}</h6>
        <div className={styles.languages}>
          <h6 onClick={() => navigate("/de")}>{de}</h6>
          <h6 onClick={() => navigate("/fr")}>{fr}</h6>
          <h6 onClick={() => navigate("/it")}>{it}</h6>
        </div>
      </div>
      <p className={styles.copyrights}>{copyrights}</p>

    </footer>
  )
}

export default Footer
