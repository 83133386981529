import React from "react"
import styles from "./textInput.module.scss"

const TextInput = ({ label }) => {
  return (
    <div className={styles.inputWrapper}>
      <label>{label}</label>
      <input />
    </div>
  )
}

export default TextInput
