import env from "./environments"

const backend = {
  [env.LOCAL]: "https://collect-backend.campax.org",
  [env.DEVELOPMENT]: "https://collect-backend.campax.org",
  [env.PRODUCTION]: "https://collect-backend.campax.org",
}

const front = {
  [env.LOCAL]: "http://collect.campax.org",
  [env.DEVELOPMENT]: "http://collect.campax.org",
  [env.PRODUCTION]: "http://collect.campax.org",
}

export const BACKEND_URL = backend[env.ENVIRONMENT]
export const SELF_URL = front[env.ENVIRONMENT]
