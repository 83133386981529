import React, { useState } from "react"
import Slide from "react-reveal/Slide"
import { Link, animateScroll as scroll } from "react-scroll"
import linkProps from "./header.config"

import styles from "./header.module.scss"
import { SELF_URL } from "../../constants/urls"

import logo from "../../../static/logos/collectCampax.png"
import menu from "../../../static/icons/menu.svg"
import LanguagePicker from "../languagePicker/languagePicker"

const quickLinks = [
  {
    id: "latestCampaignsBlock",
    translation: "linkToFeaturedCampaigns",
  },
  {
    id: "about",
    translation: "linkToFirstDescription",
  },
  {
    id: "collect",
    translation: "linkToSecondDescription",
  },
]

const Header = ({
  type,
  headerCopy,
  defaultLanguage,
  firstLanguage,
  secondLanguage,
  logoSrc,
  language,
}) => {
  const [mobileMenu, toggleMobileMenu] = useState(false)

  const scrollToTop = () => {
    scroll.scrollToTop()
  }
  return (
    <>
      {type === "landingPage" && (
        <header className={styles.headerBigScreen}>
          <div className={styles[type]}>
            <img
              className={styles.campaxLogo}
              alt="Campax Logo"
              src={logo}
              onClick={() => scrollToTop()}
            />
            <nav className={styles.navLinks}>
            <a href="https://donate.campax.org/de/pay?cid=d6a7c49a" target="_blank" rel="noreferrer">{headerCopy.donateTitle}</a>
              {quickLinks.map(link => (
                <Link {...linkProps} to={link.id}>
                  {headerCopy[link.translation]}
                </Link>
              ))}

              <LanguagePicker
                defaultLanguage={defaultLanguage}
                firstLanguage={firstLanguage}
                secondLanguage={secondLanguage}
              />
            </nav>
          </div>
        </header>
      )}
      {type === "templatePage" && (
        <header className={styles.templatePage}>
          <a href={SELF_URL}>
            <img
              className={styles.campaignLogo}
              alt="campaign logo"
              src={logoSrc || logo}
            />
          </a>
          
        </header>
        
      )}

      {type === "landingPage" && (
        <div className={styles.mobHeader}>
          <img
            src={logo}
            alt="Campax Logo"
            className={styles.logoMob}
            onClick={() => scrollToTop()}
          />
          <img
            src={menu}
            alt="hamburger menu"
            className={styles.hamburgerMenu}
            onClick={() => toggleMobileMenu(!mobileMenu)}
          />
        </div>
      )}
      {mobileMenu && (
        <>
          <Slide ssrFadeout right>
            <div className={styles.langMob}>
              <LanguagePicker
                defaultLanguage={defaultLanguage}
                firstLanguage={firstLanguage}
                secondLanguage={secondLanguage}
              />{" "}
            </div>
            <div
              className={styles.closeButton}
              onClick={() => toggleMobileMenu(!mobileMenu)}
            >
              &#8594;
            </div>
            <div className={styles.mobMenu}>
            <a href={headerCopy.donateTarget} target="_blank" rel="noreferrer">{headerCopy.donateTitle}</a>
              {quickLinks.map(link => (
                <Link
                  {...linkProps}
                  onClick={() => toggleMobileMenu(!mobileMenu)}
                  to={link.id}
                >
                  {headerCopy[link.translation]}
                </Link>
              ))}
            </div>
          </Slide>
        </>
      )}
    </>
  )
}

export default Header
