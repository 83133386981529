import React, { useState } from "react"
import { navigate } from "gatsby"
import dropdown from "../../../static/icons/dropArrow.svg"


import styles from "./languagePicker.module.scss"

const LanguagePicker = ({ defaultLanguage, firstLanguage, secondLanguage }) => {
  const [toggle, toggleLanguage] = useState(false)
  return (
    <div
      onClick={() => toggleLanguage(!toggle)}
      className={styles.languagePicker}
    >
      <img
        className={toggle ? styles.arrowUp : styles.arrowDown}
        src={dropdown}
      />
      <h3>{defaultLanguage}</h3>
      {toggle && (
        <div className={styles.languageMenu}>
          <h3 onClick={() => navigate(`/${firstLanguage}`)}>{firstLanguage}</h3>
          <h3 onClick={() => navigate(`/${secondLanguage}`)}>
            {secondLanguage}
          </h3>
        </div>
      )}
    </div>
  )
}

export default LanguagePicker
