import React from "react"
import styles from "./socialNav.module.scss"

import tw from "../../../static/icons/twitter.svg"
import fb from "../../../static/icons/facebook.svg"
import tg from "../../../static/icons/telegram.svg"
import wu from "../../../static/icons/whatsup.svg"
import ln from "../../../static/icons/linkedin.svg"
import em from "../../../static/icons/email.svg"
import ds from "../../../static/icons/discord.svg"

const socialMedia = [
  { id: 0, icon: tw },
  { id: 1, icon: fb },
  { id: 2, icon: tg },
  { id: 3, icon: wu },
  { id: 4, icon: ln },
  { id: 5, icon: em },
  { id: 6, icon: ds },
]

const SocialNav = () => {
  return (
    <div className={styles.socialNav}>
      <nav>
        <ul>
          {socialMedia.map(media => (
            <li key={media.id}>
              <img src={media.icon} />
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

export default SocialNav
